import { useCallback } from 'react';

import { Box, TrashCan } from '@carbon/icons-react';
import { IconButton } from '@carbon/react';
import { DeleteModal, ModalStateManager } from '@wastewizer/ui-components';

import { useDeleteContainerSiteMutation } from './_generated';
import { ContainerSiteTableRowFragment } from '../_generated';

export type DeleteContainerSiteProps = {
  containerSite: ContainerSiteTableRowFragment;
  afterDelete: () => void;
};

export const DeleteContainerSite: React.FunctionComponent<
  DeleteContainerSiteProps
> = ({ containerSite, afterDelete }) => {
  const [deleteContainerSite, { loading: deleting }] =
    useDeleteContainerSiteMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => (
      <IconButton
        label="Delete"
        align="top-right"
        kind="ghost"
        size="sm"
        onClick={() => {
          setOpen(true);
        }}
        disabled={!!containerSite.binBar}
      >
        <TrashCan />
      </IconButton>
    ),
    [containerSite.binBar],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <DeleteModal
        open={open}
        modalLabel={containerSite.name}
        modalHeading={`Are you sure you want to delete ${containerSite.name} and all of its associated data?`}
        objectIcon={Box}
        loading={deleting}
        onDelete={async () => {
          await deleteContainerSite({
            variables: { id: containerSite.id },
          });

          setOpen(false);
          afterDelete();
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    ),
    [containerSite, deleteContainerSite, afterDelete, deleting],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`delete-container-site-${containerSite.id}`}
    />
  );
};
