import { useEffect, useState } from 'react';

import { ComboBox, Form, NumberInput, Stack, TextInput } from '@carbon/react';
import { Permission, canForAccount } from '@wastewizer/authz';
import { setValueAsFloat } from '@wastewizer/ui-utils';
import { useFormContext } from 'react-hook-form';

import {
  ServiceLocationSelectItemWithAccountFragment,
  UserSelectItemFragment,
} from '#fragments';
import { useUser } from '#hooks/useUser';
import {
  useGetAllServiceLocationsQuery,
  useGetDriversForAccountLazyQuery,
} from './_generated';
import { commodityTypes } from './commodityTypes';
import { containerSizes } from './containerSizes';

export type ContainerSiteFormData = {
  serviceLocationId: string;
  name: string;
  emptyContainerWeight: number;
  maxNetWeight: number;
  maxGrossWeight: number;
  size: number;
  commodityType?: string;
  assignedDriverId?: string;
};

export const ContainerSiteForm: React.FunctionComponent = () => {
  const {
    register,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();
  const user = useUser();
  const { preferences: { weightLabel } = {} } = user;

  // ComboBoxes cannot be registered with destructuring
  register('serviceLocationId', { required: 'Service location is required' });
  const serviceLocationId = watch('serviceLocationId');

  register('size', { required: 'Container size is required' });
  const containerSize = watch('size');

  register('commodityType');
  const commodityType = watch('commodityType');

  register('assignedDriverId');
  const assignedDriverId = watch('assignedDriverId');

  const emptyContainerWeight = watch('emptyContainerWeight');
  const maxNetWeight = watch('maxNetWeight');

  const [serviceLocations, setServiceLocations] = useState<
    ServiceLocationSelectItemWithAccountFragment[]
  >([]);

  useGetAllServiceLocationsQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const allowedServiceLocations = data.serviceLocations.reduce(
        (acc, serviceLocation) => {
          if (
            canForAccount(
              user,
              serviceLocation.account.id,
              Permission.CAN_CREATE_CONTAINER_SITES,
            )
          ) {
            acc.push(serviceLocation);
          }
          return acc;
        },
        [],
      );

      setServiceLocations(allowedServiceLocations);

      if (!serviceLocationId && allowedServiceLocations.length === 1) {
        setValue('serviceLocationId', allowedServiceLocations[0].id);
      }
    },
  });

  const [fetchDrivers, { data: driverData }] = useGetDriversForAccountLazyQuery(
    {
      onCompleted: (data) => {
        if (!data.drivers.find((d) => d.id === assignedDriverId)) {
          resetField('assignedDriverId', { defaultValue: undefined });
        }
      },
    },
  );

  useEffect(() => {
    setValue('maxGrossWeight', maxNetWeight + emptyContainerWeight, {
      shouldValidate: false,
    });
  }, [emptyContainerWeight, maxNetWeight, setValue]);

  useEffect(() => {
    if (serviceLocationId && serviceLocations.length) {
      fetchDrivers({
        variables: {
          accountId: serviceLocations.find((sl) => sl.id === serviceLocationId)
            ?.account.id,
        },
      });
    }
  }, [fetchDrivers, serviceLocationId, serviceLocations]);

  return (
    <Form>
      <Stack gap={6}>
        <ComboBox
          id="serviceLocationId"
          data-1p-ignore
          titleText="Service location"
          placeholder="Select service location"
          helperText="The service location for this container site"
          items={serviceLocations}
          itemToString={(item?: ServiceLocationSelectItemWithAccountFragment) =>
            item?.name ?? ''
          }
          shouldFilterItem={(menu) =>
            menu.item.name
              .toLowerCase()
              .includes(menu.inputValue?.toLowerCase())
          }
          selectedItem={serviceLocations.find(
            (sl) => sl.id === serviceLocationId,
          )}
          onChange={(e) => {
            setValue('serviceLocationId', e.selectedItem?.id);
          }}
          invalid={!!errors.serviceLocationId}
          invalidText={errors.serviceLocationId?.message as string}
        />

        <TextInput
          {...register('name', { required: 'Name is required' })}
          id="name"
          data-1p-ignore
          labelText="Name"
          helperText="The name of the container site"
          invalid={!!errors.name}
          invalidText={errors.name?.message as string}
        />

        <NumberInput
          {...register('emptyContainerWeight', {
            required: 'Empty container weight is required',
            min: {
              value: 0,
              message: `Empty container weight must be greater than 0`,
            },
            max: {
              value: weightLabel.barbaraV1Max,
              message: `Empty container weight must be less than ${weightLabel.barbaraV1Max.toFixed(
                1,
              )} ${weightLabel.plural}`,
            },
            setValueAs: setValueAsFloat,
          })}
          id="emptyContainerWeight"
          label="Empty container weight"
          helperText={`Empty container weight in ${weightLabel.plural}`}
          invalid={!!errors.emptyContainerWeight}
          invalidText={errors.emptyContainerWeight?.message as string}
          min={Number.NEGATIVE_INFINITY}
          max={Number.POSITIVE_INFINITY}
          defaultValue={emptyContainerWeight || 0}
          hideSteppers
          onChange={(_, { value }) =>
            setValue('emptyContainerWeight', setValueAsFloat(value as string))
          }
        />

        <Stack gap={6} orientation="horizontal">
          <NumberInput
            {...register('maxNetWeight', {
              required: 'Max net weight is required',
              min: {
                value: 0,
                message: `Max net weight must be greater than 0`,
              },
              setValueAs: setValueAsFloat,
            })}
            id="maxNetWeight"
            label="Max net weight"
            helperText={`Max net weight in ${weightLabel.plural}`}
            invalid={!!errors.maxNetWeight}
            invalidText={errors.maxNetWeight?.message as string}
            min={Number.NEGATIVE_INFINITY}
            max={Number.POSITIVE_INFINITY}
            defaultValue={maxNetWeight || 0}
            hideSteppers
            onChange={(_, { value }) =>
              setValue('maxNetWeight', setValueAsFloat(value as string))
            }
          />

          <TextInput
            {...register('maxGrossWeight', {
              required: 'Max gross weight is required',
              min: {
                value: 0,
                message: `Max gross weight must be greater than 0`,
              },
              max: {
                value: weightLabel.barbaraV1Max,
                message: `Max gross weight must be less than ${weightLabel.barbaraV1Max.toFixed(
                  1,
                )} ${weightLabel.plural}`,
              },
              setValueAs: setValueAsFloat,
            })}
            id="maxGrossWeight"
            labelText="Max gross weight"
            helperText={`Max gross weight in ${weightLabel.plural}`}
            invalid={!!errors.maxGrossWeight}
            invalidText={errors.maxGrossWeight?.message as string}
            disabled
          />
        </Stack>

        <Stack gap={6} orientation="horizontal">
          <ComboBox
            id="size"
            titleText="Container size"
            placeholder="Select container size"
            helperText="The container size for the container at this site"
            items={containerSizes}
            itemToString={(item?: (typeof containerSizes)[number]) =>
              item?.name ?? ''
            }
            shouldFilterItem={(menu) =>
              menu.item.name
                .toLowerCase()
                .includes(menu.inputValue?.toLowerCase())
            }
            selectedItem={containerSizes.find((s) => s.id === containerSize)}
            onChange={(e) => {
              setValue('size', e.selectedItem?.id);
            }}
            invalid={!!errors.size}
            invalidText={errors.size?.message as string}
          />

          <ComboBox
            id="commodityType"
            titleText="Commodity"
            placeholder="Select commodity type"
            helperText="The commodity type in the container at this site"
            allowCustomValue
            items={commodityTypes}
            shouldFilterItem={(menu) =>
              menu.item.toLowerCase().includes(menu.inputValue?.toLowerCase())
            }
            selectedItem={commodityType}
            onChange={(e) => {
              setValue(
                'commodityType',
                e.inputValue || e.selectedItem || undefined,
              );
            }}
          />
        </Stack>

        <ComboBox
          id="assignedDriverId"
          data-1p-ignore
          titleText="Assigned driver"
          placeholder="Select driver"
          helperText="The driver assigned to this container site"
          items={driverData?.drivers || []}
          itemToString={(item?: UserSelectItemFragment) => item?.name ?? ''}
          shouldFilterItem={(menu) =>
            menu.item.name
              .toLowerCase()
              .includes(menu.inputValue?.toLowerCase())
          }
          selectedItem={driverData?.drivers.find(
            (d) => d.id === assignedDriverId,
          )}
          onChange={(e) => {
            setValue('assignedDriverId', e.selectedItem?.id);
          }}
          readOnly={!driverData?.drivers.length}
          warn={!driverData?.drivers.length}
          warnText="There are no drivers setup for this account"
        />
      </Stack>
    </Form>
  );
};
