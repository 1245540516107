import { InlineLoading } from '@carbon/react';

import { useGetMissingTicketsCountQuery } from './_generated';

type MissingWeightTicketsCountProps = {
  id: string;
};

export const MissingWeightTicketsCount: React.FunctionComponent<
  MissingWeightTicketsCountProps
> = ({ id }) => {
  const { data, loading } = useGetMissingTicketsCountQuery({
    variables: {
      id,
    },
  });

  if (loading) {
    return (
      <InlineLoading
        status="active"
        iconDescription="Counting missing weight tickets"
      />
    );
  }

  return (
    data.containerSite.containerCyclesWithoutWeightTicketCount || undefined
  );
};
