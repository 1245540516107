import React, { useCallback, useState } from 'react';

import { green60 } from '@carbon/colors';
import { Box, Link, ProgressBar } from '@carbon/icons-react';
import {
  ComboBox,
  Form,
  IconButton,
  Loading,
  Modal,
  Stack,
} from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';
import { useForm } from 'react-hook-form';

import { BinBarSelectItemFragment } from '#fragments';
import {
  useAssignBinBarMutation,
  useGetAllBinBarsLazyQuery,
} from './_generated';
import styles from './AssignBinBar.module.scss';
import { ContainerSiteTableRowFragment } from '../_generated';

type AssignBinBarFormData = {
  binBarId: string;
};

export type AssignBinBarProps = {
  containerSite: ContainerSiteTableRowFragment;
  afterAssign: () => void;
};

export const AssignBinBar: React.FunctionComponent<AssignBinBarProps> = ({
  containerSite,
  afterAssign,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AssignBinBarFormData>({
    mode: 'onSubmit',
  });

  const [binBars, setBinBars] = useState<BinBarSelectItemFragment[]>([]);

  const [loadBinBars, { loading: binBarsLoading }] = useGetAllBinBarsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBinBars(data.binBars.filter((bb) => !bb.containerSite));
    },
  });

  const [assignBinBar, { loading: assigning }] = useAssignBinBarMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => (
      <IconButton
        label="Assign BinBar"
        align="top-right"
        kind="ghost"
        size="sm"
        onClick={() => {
          setOpen(true);
          loadBinBars();
        }}
      >
        <Link />
      </IconButton>
    ),
    [loadBinBars],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => {
      // ComboBox cannot be fully controlled with react-hook-form
      register('binBarId', { required: 'BinBar is required' });
      const binBarId = watch('binBarId');

      return (
        <Modal
          open={open}
          preventCloseOnClickOutside
          modalHeading={`Assign a BinBar to ${containerSite.name}`}
          primaryButtonText="Save"
          onRequestClose={() => {
            setOpen(false);
          }}
          onRequestSubmit={handleSubmit(async (data: AssignBinBarFormData) => {
            await assignBinBar({
              variables: {
                id: containerSite.id,
                binBarId: data.binBarId,
              },
            });

            reset();

            setOpen(false);
            afterAssign();
          })}
        >
          <>
            <Loading withOverlay active={binBarsLoading || assigning} />
            <Form>
              <Stack gap={6}>
                <ComboBox
                  id="binBarId"
                  titleText="BinBar"
                  placeholder="Select BinBar"
                  helperText="The BinBar for this container site"
                  items={binBars}
                  itemToString={(item?: BinBarSelectItemFragment) =>
                    item?.name ?? ''
                  }
                  shouldFilterItem={(menu) =>
                    menu.item.name
                      .toLowerCase()
                      .includes(menu.inputValue?.toLowerCase())
                  }
                  selectedItem={binBars.find((bb) => bb.id === binBarId)}
                  onChange={(e) => {
                    setValue('binBarId', e.selectedItem?.id);
                  }}
                  invalid={!!errors.binBarId}
                  invalidText={errors.binBarId?.message}
                />
                <div className={styles.modalBodyContent}>
                  <Box size={80} />
                  <Link color={green60} size={40} />
                  <ProgressBar size={80} />
                </div>
              </Stack>
            </Form>
          </>
        </Modal>
      );
    },
    [
      containerSite,
      binBars,
      binBarsLoading,
      assigning,
      assignBinBar,
      afterAssign,
      reset,
      setValue,
      errors,
      handleSubmit,
    ],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`assign-binbar-${containerSite.id}`}
    />
  );
};
