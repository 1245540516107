import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetMissingTicketsCountDocument = gql`
  query GetMissingTicketsCount($id: ID!) {
    containerSite: containerSiteById(id: $id) {
      id
      containerCyclesWithoutWeightTicketCount
    }
  }
`;

/**
 * __useGetMissingTicketsCountQuery__
 *
 * To run a query within a React component, call `useGetMissingTicketsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingTicketsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingTicketsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMissingTicketsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMissingTicketsCountQuery,
    GetMissingTicketsCountQueryVariables
  > &
    (
      | { variables: GetMissingTicketsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissingTicketsCountQuery,
    GetMissingTicketsCountQueryVariables
  >(GetMissingTicketsCountDocument, options);
}
export function useGetMissingTicketsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissingTicketsCountQuery,
    GetMissingTicketsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissingTicketsCountQuery,
    GetMissingTicketsCountQueryVariables
  >(GetMissingTicketsCountDocument, options);
}
export function useGetMissingTicketsCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMissingTicketsCountQuery,
        GetMissingTicketsCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMissingTicketsCountQuery,
    GetMissingTicketsCountQueryVariables
  >(GetMissingTicketsCountDocument, options);
}
export type GetMissingTicketsCountQueryHookResult = ReturnType<
  typeof useGetMissingTicketsCountQuery
>;
export type GetMissingTicketsCountLazyQueryHookResult = ReturnType<
  typeof useGetMissingTicketsCountLazyQuery
>;
export type GetMissingTicketsCountSuspenseQueryHookResult = ReturnType<
  typeof useGetMissingTicketsCountSuspenseQuery
>;
export type GetMissingTicketsCountQueryResult = Apollo.QueryResult<
  GetMissingTicketsCountQuery,
  GetMissingTicketsCountQueryVariables
>;
export type GetMissingTicketsCountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetMissingTicketsCountQuery = {
  __typename?: 'Query';
  containerSite: {
    __typename?: 'ContainerSite';
    id: string;
    containerCyclesWithoutWeightTicketCount: number;
  };
};
